.sidebar-menu {
  @apply flex inset-0 fixed flex-col w-80 bg-white dark:bg-primary-900 transition-all ease-linear -translate-x-80 z-1000;

  @media (max-width: 400px) {
    width: 90vw;
    transform: translateX(-90vw);
  }

  hr {
    @apply border-gray-200 dark:border-gray-700;
  }
}

.sidebar-menu__root--visible {
  .sidebar-menu {
    transform: translateX(0);
  }
}

.sidebar-menu .wtf-panel {
  box-shadow: none !important;
  margin: 0;
}
