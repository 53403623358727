.notification-list {
  @apply w-full flex flex-col items-center space-y-2 sm:items-end z-[1001] relative;
}

.notification-bar {
  @apply max-w-sm w-full flex flex-row items-center bg-white dark:bg-gray-900 shadow-lg rounded-lg pointer-events-auto dark:ring-2 dark:ring-gray-800 overflow-hidden;

  &::before {
    font-family: 'Font Awesome 5 Free';
    font-weight: 900;
    font-size: 20px;
  }
}

.notification-bar-success {
  @apply text-success-500;

  &::before {
    @apply ml-4;
    content: '\f058';
  }
}

.notification-bar-error {
  @apply text-danger-500 ml-4;

  &::before {
    @apply ml-4;
    content: '\f057';
  }
}

.notification-bar-info {
  @apply text-primary-500 ml-4;

  &::before {
    @apply ml-4;
    content: '\f05a';
  }
}

.notification-bar-wrapper {
  @apply p-4 flex items-center justify-between w-full space-x-2;
}

.notification-bar-title {
  @apply mb-1 text-sm font-medium text-gray-900 dark:text-gray-100;
}

.notification-bar-message {
  @apply text-sm text-gray-800 dark:text-gray-200;
}

.notification-bar-action a {
  @apply inline-flex items-center px-2.5 py-1 border border-solid border-gray-300
    shadow-sm text-xs font-medium rounded-full text-gray-700 bg-white
    hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2
    focus:ring-primary-500;
}
