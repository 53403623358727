.filter-settings-panel {
  .item-list article {
    border-bottom: 1px solid var(--primary-text-color--faint);

    &:last-child {
      border-bottom: 0;
    }
  }

  .fields-group .two-col {
    display: flex;
    align-items: flex-start;
    width: 100%;
    justify-content: flex-start;
    flex-wrap: wrap;

    div.input {
      width: 45%;
      margin-right: 20px;

      .label_input {
        width: 100%;
      }
    }

    @media (max-width: 485px) {
      div.input {
        width: 100%;
        margin-right: 5px;

        .label_input {
          width: auto;
        }
      }
    }
  }

  .input.boolean {
    .label_input {
      @apply relative pl-7 text-black dark:text-white;

      label {
        @apply text-sm;
      }

      &__wrapper {
        @apply static;
      }

      input[type="checkbox"] {
        position: absolute;
        top: 3px;
        left: 0;
      }
    }

    .hint {
      @apply block pl-7 text-xs text-gray-500 dark:text-gray-400;
    }
  }

  .filter__container {
    @apply flex justify-between py-5 px-2 text-sm text-black dark:text-white;

    .filter__phrase,
    .filter__contexts,
    .filter__details {
      @apply py-1;
    }

    span.filter__list-label {
      @apply pr-1 text-gray-500 dark:text-gray-400;
    }

    span.filter__list-value span {
      @apply pr-1 capitalize;

      &::after {
        content: ",";
      }

      &:last-of-type {
        &::after {
          content: "";
        }
      }
    }

    .filter__delete {
      @apply flex items-center h-5 m-2.5 cursor-pointer;

      span.filter__delete-label {
        @apply text-gray-500 dark:text-gray-400 font-semibold;
      }

      .filter__delete-icon {
        @apply mx-1 text-gray-500 dark:text-gray-400;
      }
    }
  }
}
