.public-layout {
  .footer {
    display: flex;
    padding: 40px 0;
    font-size: 12px;
    justify-content: center;
    align-items: center;
    margin-top: auto;
    color: #fff;
    background-color: rgba(0, 0, 0, 0.15);

    @media screen and (max-width: 1024px) {
      padding: 40px 20px;
    }

    .footer-container {
      display: flex;
      width: 1280px;
      align-items: center;
      padding: 0 20px;
      flex-direction: column-reverse;
      justify-content: center;
    }

    h4 {
      text-transform: uppercase;
      font-weight: 700;
      margin-bottom: 8px;
      color: var(--primary-text-color--faint);
      font-size: 1.6rem;
      line-height: 1.5;

      a {
        color: inherit;
        text-decoration: none;
      }
    }

    .copyright {
      margin: 0 auto auto;
      text-align: center;

      span {
        font-size: 1.3rem;
        font-weight: 500;
        line-height: 1.5;
      }
    }

    ul {
      display: flex;
      flex-wrap: wrap;
      margin: 0 auto 20px;

      li {
        a {
          text-decoration: none;
          color: $color-5-dark;
          font-size: 1.3rem;
          font-weight: 500;
          line-height: 1.5;
          padding: 10px 4px;
          margin-left: 10px;

          &:hover,
          &:active,
          &:focus {
            text-decoration: underline;
          }
        }
      }
    }
  }
}
