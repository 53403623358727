.card {
  & > a {
    display: block;
    text-decoration: none;
    color: inherit;
    box-shadow: 0 0 3px 0 rgba(0, 0, 0, 0.3);
    border-radius: 4px;
    overflow: hidden;

    @media screen and (max-width: $no-gap-breakpoint) {
      box-shadow: none;
    }

    &:hover,
    &:active,
    &:focus {
      .card__bar {
        background-color: var(--brand-color--faint);
      }
    }
  }
}

.account {
  padding: 10px;
  position: relative;

  &:not(:last-of-type) {
    border-bottom: 1px solid var(--brand-color--med);
  }

  &.compact {
    padding: 0;
    border-bottom: 0;

    .account__avatar-wrapper {
      margin-left: 0;
    }
  }

  .account__display-name {
    flex: 1 1 auto;
    display: block;
    color: var(--primary-text-color--faint);
    overflow: hidden;
    text-decoration: none;
    font-size: 14px;

    .display-name__name {
      display: flex;
    }
  }

  &__note {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    color: var(--primary-text-color--faint);
  }
}

.account__wrapper {
  display: flex;
  align-items: center;
}

.account__avatar-wrapper {
  float: left;
  margin-right: 12px;
}

.account__avatar {
  @include avatar-radius;
  position: relative;
  background-color: var(--brand-color--faint);

  &-inline {
    display: inline-block;
    vertical-align: middle;
    margin-right: 5px;
  }

  &-composite {
    @include avatar-radius;
    overflow: hidden;

    & > div {
      @include avatar-radius;
      float: left;
      position: relative;
      box-sizing: border-box;
    }
  }
}

a .account__avatar {
  cursor: pointer;
}

.account__avatar-overlay {
  @include avatar-size(48px);

  &-base {
    @include avatar-radius;
    @include avatar-size(36px);
  }

  &-overlay {
    @include avatar-radius;
    @include avatar-size(24px);
    position: absolute;
    bottom: 0;
    right: 0;
    z-index: 1;

    &.still-image {
      position: absolute;
    }
  }
}

.account__relationship {
  height: auto;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;

  button {
    margin-left: 5px;
  }

  .svg-icon {
    width: 20px;
    height: 20px;
  }
}

.account-authorize {
  padding: 14px 10px;

  .detailed-status__display-name {
    display: block;
    margin-bottom: 15px;
    overflow: hidden;
  }
}

.account-authorize__avatar {
  float: left;
  margin-right: 10px;
}

.account-gallery__container {
  display: flex;
  flex-wrap: wrap;

  .empty-column-indicator {
    margin: -4px -2px;
  }
}

.account-gallery__item {
  @apply rounded-lg p-1;
  border: 0;
  box-sizing: border-box;
  display: block;
  position: relative;
  overflow: hidden;

  a {
    background: var(--brand-color--faint);
  }

  video,
  img {
    @apply rounded-lg;
  }

  &__icons {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    .svg-icon {
      @apply h-6 w-6;
    }
  }
}

.account--panel {
  background: var(--brand-color--faint);
  border-top: 1px solid var(--brand-color--med);
  border-bottom: 1px solid var(--brand-color--med);
  display: flex;
  flex-direction: row;
  padding: 10px 0;

  &__button .svg-icon {
    height: 20px;
    width: 20px;
  }
}

.account__section-headline {
  button,
  a {
    flex: none;
    padding: 18px 15px;
    font-size: 16px;
    font-weight: 600;

    &.active {
      &::before,
      &::after {
        border-color: transparent transparent var(--foreground-color);
      }
    }
  }
}

.account__moved-note {
  padding: 14px 10px;
  padding-bottom: 16px;
  background: var(--brand-color--faint);
  border-top: 1px solid var(--brand-color--med);
  border-bottom: 1px solid var(--brand-color--med);

  &__message {
    position: relative;
    margin-left: 58px;
    color: var(--primary-text-color);
    padding: 8px 0;
    padding-top: 0;
    padding-bottom: 4px;
    font-size: 14px;

    > span {
      display: block;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  &__icon-wrapper {
    left: -26px;
    position: absolute;
  }

  .detailed-status__display-avatar {
    position: relative;
  }

  .detailed-status__display-name {
    margin-bottom: 0;
  }
}

.relationship-tag {
  color: var(--primary-text-color);
  margin-bottom: 4px;
  margin-left: 4px;
  display: block;
  vertical-align: top;
  background-color: var(--background-color);
  text-transform: uppercase;
  font-size: 11px;
  font-weight: 500;
  padding: 4px;
  border-radius: 4px;
  opacity: 0.7;
  white-space: nowrap;
  transition: 0.2s;

  &:hover {
    opacity: 1;
  }
}

.account__joined-at {
  padding-left: 3px;
  font-size: 14px;
  display: flex;
  white-space: nowrap;
  flex-shrink: 0;
  color: var(--primary-text-color--faint);

  .svg-icon {
    padding-right: 3px;
  }
}
