.react-toggle {
  display: inline-block;
  position: relative;
  cursor: pointer;
  background-color: transparent;
  border: 0;
  padding: 0;
  user-select: none;
  -webkit-tap-highlight-color: rgba($base-overlay-background, 0);
  -webkit-tap-highlight-color: transparent;
}

.react-toggle-screenreader-only {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}

.react-toggle--disabled {
  cursor: not-allowed;
  opacity: 0.5;
  transition: opacity 0.25s;
}

.react-toggle-track {
  @apply bg-gray-500 dark:bg-gray-700 w-[50px] p-0 rounded-full transition-colors;
  height: var(--input-height);
}

.react-toggle--checked .react-toggle-track {
  @apply bg-primary-600 dark:bg-accent-blue;
}

.react-toggle-track-check {
  position: absolute;
  width: 14px;
  height: 10px;
  top: 0;
  bottom: 0;
  margin-top: auto;
  margin-bottom: auto;
  line-height: 0;
  left: 8px;
  opacity: 0;
  transition: opacity 0.25s ease;
}

.react-toggle--checked .react-toggle-track-check {
  opacity: 1;
  transition: opacity 0.25s ease;
}

.react-toggle-track-x {
  position: absolute;
  width: 10px;
  height: 10px;
  top: 0;
  bottom: 0;
  margin-top: auto;
  margin-bottom: auto;
  line-height: 0;
  right: 10px;
  opacity: 1;
  transition: opacity 0.25s ease;
}

.react-toggle--checked .react-toggle-track-x {
  opacity: 0;
}

.react-toggle-thumb {
  position: absolute;
  top: 1px;
  left: 1px;
  width: 28px;
  height: 28px;
  border: 1px solid #fff;
  border-radius: 50%;
  background-color: #fff;
  box-sizing: border-box;
  transition: all 0.25s ease;
  transition-property: border-color, left;
}

.react-toggle--checked .react-toggle-thumb {
  @apply border-primary-600 dark:border-accent-blue;
  left: 21px;
}
